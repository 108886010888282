<template>
  <div class="answer-detail">
    <div class="answer-detail-header">
      <div>
        班级
        <el-select v-model="classValue" placeholder="请选择" style="margin-left: 8px" @change="selectClass">
          <el-option
              v-for="item in classList"
              :key="item.class_id"
              :label="item.class_name"
              :value="item.class_id">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="toBack">返回</el-button>
    </div>
    <el-scrollbar class="answer-detail-table">
      <div class="detail-table-header">
        <div class="table-header-item"><span>学生名称</span></div>
        <div class="table-header-item"><span>班级名称</span></div>
        <div class="table-header-item"><span>训练次数</span></div>
        <div class="table-header-item"><span>操作</span></div>
      </div>
      <div class="detail-table-body" v-if="studentList.length">
        <div class="table-body-item" v-for="item in studentList">
          <div class="body-item-top">
            <div class="item-top-item"><i class="el-icon-arrow-right" :style="item.isShow ? 'transform:rotate(90deg)' : ''" @click="item.isShow = !item.isShow"></i><span>{{item.nickname}}</span></div>
            <div class="item-top-item"><span>{{item.class_name}}</span></div>
            <div class="item-top-item"><span>共{{item.total}}次</span></div>
            <div class="item-top-item"><span></span></div>
          </div>
          <template v-if="item.isShow">
            <div class="body-item-bottom" v-for="itemChildren in item.children">
              <div class="item-bottom-item"><span></span></div>
              <div class="item-bottom-item"><span></span></div>
              <div class="item-bottom-item"><span>第{{itemChildren.number}}次</span></div>
              <div class="item-bottom-item" @click="toLookDetail(item.user_id,itemChildren.student_theory_train_log_id)"><span>查看详情</span></div>
            </div>
          </template>
        </div>
      </div>
      <div v-else style="text-align: center;line-height: 70px" v-if="">暂无数据</div>
    </el-scrollbar>
    <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   :total="listPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="listCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import {trainAnswerDetailListTeacher} from '@/utils/apis'
export default {
  name: "ExamAnswerDetail",
  data(){
    return {
      classValue: '',
      classList: [],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      isShow:true,
      studentList:[],
    }
  },
  mounted() {
    this.getStudentList();
  },
  methods:{
    // 获取学生列表
    getStudentList(){
      let params = {
        theory_test_paper_id:this.$route.query.id,
        course_id:this.$route.query.course_id,
        chapter_id:this.$route.query.chapter_id,
        lesson_id:this.$route.query.lesson_id,
        paging:1,
        page:this.listPages.currentPageNum,
        pageSize:this.listPages.eachPageNum
      }
      if(this.classValue){
        params.class_id = this.classValue
      }
      trainAnswerDetailListTeacher(params).then((res)=>{
        if(res.data.list.length){
          res.data.list.forEach(item=>{
            this.$set(item, 'isShow', true)
          })
        }
        this.classList = res.data.class_data;
        this.studentList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    selectClass(){
      this.getStudentList();
    },
    // 查看详情
    toLookDetail(userId,trainLogId){
      this.$router.push({
        path:'/trainingCenter/examTrainStudentAnswerDetail',
        query:{
          userId,
          trainLogId
        }
      })
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getStudentList();
    },
    toBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.answer-detail{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .answer-detail-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answer-detail-table{
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    height: 1%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .detail-table-header{
      display: flex;
      background: #F6F6F6;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      .table-header-item{
        width: 25%;
        text-align: center;
        box-sizing: border-box;
        &:first-of-type{
          text-align: left;
          padding-left: 58px;
        }
        span{
          color: #666666;
          font-size: 16px;
        }
      }
    }
    .detail-table-body{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-body-item{
        display: flex;
        flex-direction: column;
        .body-item-top, .body-item-bottom{
          box-sizing: border-box;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #EEEEEE;
          .item-top-item, .item-bottom-item{
            width: 25%;
            height: 70px;
            line-height: 70px;
            text-align: center;
            box-sizing: border-box;
            &:first-of-type{
              text-align: left;
              padding-left: 26px;
              i{
                cursor: pointer;
                color: #333333;
                font-weight: bold;
              }
              span{
                margin-left: 18px;
              }
            }
            span{
              color: #333333;
              font-size: 16px;
            }
            &.item-bottom-item{
              &:last-of-type{
                span{
                  color: #1CB064;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>